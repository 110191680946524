/** {
    margin: 0;
    padding: 0;
    background-color: black;
    font-family: 'Helvetica', 'Arial', sans-serif;
    color: white;
    
    }*/

    @keyframes myAnim {
        0% {
            opacity: 0;
          }
        
          100% {
            opacity: 1;
          }
      }
    
    .fades {
        animation: myAnim 1s ease 0s 1 normal forwards;
    }

    #buyordownload, #buyordownloadalt {
        display: none !important;
    }

#share, #sharealt {
    display: none !important;
}

html {
    background-color: black;
}
    * {
        margin: 0;
 padding: 0;

        font-family: 'Helvetica', 'Arial', sans-serif;
        background-color: black;
    }

.container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 80%;
    height: 100%;
    flex-direction: column;
}

img {
    width: 50%;
    margin: 0 auto !important; 
}

.array {
    width: 60%;
    margin: 0 auto !important; 
}

.launchLogo {
    width: 40%;
}
.react-audio-player {
    margin-bottom: 20px;
    
    
    width: 35%;
}

.unreleased {
    width: 45%;
    height: 42px;
    margin-bottom: 20px;
}


#sharelink, #sharelinkalt {
    display: none;
}

h1 
{
    color: white;
    font-weight: bolder !important;
}

.streaming {
    width: 20%;
    margin-bottom: 5px !important;
}

.streamingMini {
    width: 10%;
    margin-bottom: 5px !important;
}

a {
    text-decoration: underline;
    text-decoration-color: white !important;
    color: white !important;
}



a:focus {
    color: gray !important;
    text-decoration-color: gray !important;
}

a:hover {
    color: gray !important;
    text-decoration-color: gray !important;
}

.btn-primary {
    color: black !important;
    background-color: white !important;
    border-color: white !important;
}

a.dropdown-item {
    color: black !important;
}

iframe {
    width: 80%;
    height: 450px;
}

h2 {
    color: white;
}

.glitch-embed-wrap {
    width: 80%;
    height: 600px;
}

.footer {
    width: 15% !important;
}

input {
    
    color: white;
    outline: none;
    border: 0px solid;
}

.form-control-md {
    color: white !important;
    width: 125px !important;
    background-color: transparent !important;
}

.btn-md {
    margin-top: 10px;
}

.bandcamp {
    border: 0; width: 350px; height: 786px; margin: 0 auto; display: block;
}

.lazyloadInspo {
    display: none;
    width: 85%;
    margin: 0 auto;
}
.lazyloadVideos {
    display: none;
    margin: 0 auto;
    width: 85%;

}



@media only screen and (min-width: 0px) and (max-width: 800px)
{
    .bandcamp {
        border: 0; width: 300px; height: 600px; margin: 0 auto;
    }
    
  .react-audio-player {
      width: 80%;
  }

  .unreleased {
    width: 90%;
    
}
  img {
    width: 90%;
}

.array {
    width: 95%;
}
.launchLogo {
    width: 90%;
}
    .streaming { 
        width: 40%;
    }
    .streamingMini { 
        width: 40%;
    }

    iframe {
        width: 100%;
        height: 300px; /*252.5*/
    }

    .glitch-embed-wrap {
        width: 100%;
        height: 420px;
    }
    .footer {
        width: 50% !important;
    }

    input {
        color: white;
        outline: none;
        border: 0px solid;
    }
}

